import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  addCommas (event) {
    const input = event.target;
    input.addEventListener("keyup", function () {
      const numWithCommas = input.value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      input.value = numWithCommas;
    });
  }
}

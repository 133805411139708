import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tableTagSelect"];

  showTableTagSelect () {
    this.tableTagSelectTarget.classList.remove("hidden");
  }

  hideTableTagSelect () {
    this.tableTagSelectTarget.value = "Income Statement";
    this.tableTagSelectTarget.classList.add("hidden");
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["parentItemSelect", "subItemSelect", "subItemOption"];

  toggleSubItemSelect () {
    if (this.parentItemSelectTarget.value !== "") {
      this.subItemSelectTarget.value = "";

      for (const subItem of this.subItemOptionTargets) {
        subItem.hidden = subItem.value !== "" && subItem.dataset.parent !== this.parentItemSelectTarget.value;
      }
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["address", "expiry"];

  connect () {
    this.toggleLiquorLicenseInfo();
  }

  toggleLiquorLicenseInfo () {
    this.expiryTargets.forEach((expiry) => {
      expiry.hidden = this.addressTarget.value[0] !== expiry.dataset.addressId;
    });
  }
}

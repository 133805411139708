import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["email"];
  static values = { readable: Boolean };

  connect () {
    if (this.readableValue) {
      const allowedMillisecondsForScrollToNewest = 2000;
      setTimeout(this.observeEmailsScrolledIntoViewport, allowedMillisecondsForScrollToNewest);
    }
  }

  observeEmailsScrolledIntoViewport = () => {
    const intersectionObserver = new IntersectionObserver(function (entries) {
      function markEmailAsRead (entry) {
        const form = entry.target.querySelector("form");
        Teamshares.Rails.fire(form, "submit");
      }
      const visibleEntries = entries.filter(entry => entry.isIntersecting);
      visibleEntries.forEach(markEmailAsRead);
      const unreadEmails = document.getElementById("unread-emails");
      if (unreadEmails) {
        const newNumUnread = parseInt(unreadEmails.innerText) - visibleEntries.length;
        if (newNumUnread <= 0) {
          unreadEmails.remove();
        } else {
          unreadEmails.innerText = `${newNumUnread}`;
        }
      }

      visibleEntries.map(entry => intersectionObserver.unobserve(entry.target));
    }, { threshold: [0.05] });

    this.unreadEmails().forEach(el => intersectionObserver.observe(document.getElementById(el.id)));
  };

  unreadEmails () {
    return this.emailTargets.filter(el => el.dataset.emailRead === "false");
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["taxReturnDocuments"];

  viewAllTaxReturnDocuments () {
    this.taxReturnDocumentsTargets.forEach(function (element) {
      window.open(element.getAttribute("href"));
    });
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "inputList",
    "inputTemplate",
    "itemContainer",
    "doNotClear",
    "doNotHide",
    "title",
  ];

  addInput () {
    const newInput = this.inputTemplateTarget.cloneNode(true);
    const newIndex = this.inputListTarget.children.length;
    this.clearInputs(newInput, newIndex);
    this.inputListTarget.appendChild(newInput);
  }

  addItem () {
    const newInput = this.inputTemplateTarget.cloneNode(true);
    const newIndex = this.generateRandomIndex();
    const clearTrixEditor = this.clearTrixEditor.bind(newInput, newIndex);

    this.clearInputs(newInput, newIndex);
    newInput.addEventListener("trix-before-initialize", function clearTrix () {
      clearTrixEditor();
      newInput.removeEventListener("trix-before-initialize", clearTrix);
    });
    this.itemContainerTarget.appendChild(newInput);
    this.unhideItems();
    this.restackItems();
  }

  deleteItem (event) {
    const elementToBeDeleted = event.currentTarget.closest(event.currentTarget.dataset.parentItem);
    elementToBeDeleted.remove();
    this.restackItems();
  }

  clearInputs (newInput, newIndex) {
    newInput.querySelectorAll("sl-input, input").forEach((input) => {
      if (!this.doNotClearTargets.some((target) => target.isEqualNode(input))) input.value = "";
      this.replaceChildIndex(input, newIndex);
    });
  }

  replaceChildIndex (input, index) {
    input.setAttribute("name", input.name.replace(/\[(\d+)]/, `[${index}]`));
    if (input.dataset.testId) input.setAttribute("data-test-id", input.dataset.testId.replace(/\d+/, index));
    input.id = input.id.replace(/\d+_/, `${index}_`);
  }

  // Remove the trix-toolbar tag and remove all the toolbar attributes from the trix-editor tag
  // So when trix initilizes, it will build a new toolbar. If not cleared, the old toolbar won't be responsive
  clearTrixEditor (index) {
    const trixToolbar = this.querySelector("trix-toolbar");
    trixToolbar.remove();

    const trixEditor = this.querySelector("trix-editor");
    trixEditor.removeAttribute("toolbar");
    trixEditor.removeAttribute("trix-id");
    trixEditor.setAttribute("input", trixEditor.getAttribute("input").replace(/\d+_/, `${index}_`));
    trixEditor.setAttribute("id", index);
    trixEditor.dataset.field = "";
    trixEditor.dataset.testId = "";
  }

  restackItems () {
    const oneItemLeft = this.itemContainerTarget.children.length === 1;

    this.titleTargets.forEach((title, index) => {
      title.innerHTML = title.innerHTML.replace(/#\d+/, `#${index + 1}`);

      if (oneItemLeft) title.querySelector("sl-icon-button").hidden = true;
    });
  }

  // Unhide all the items/targets that are currently hidden (close button on the first item, for example)
  unhideItems () {
    this.doNotHideTargets.forEach((target) => {
      target.hidden = false;
    });
  }

  generateRandomIndex () {
    return Math.floor(Math.random() * 1000);
  }
}

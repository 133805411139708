import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggleDisplay", "textField"];

  hide () {
    this.toggleDisplayTargets.forEach(target => {
      const fromClass = target.dataset.from;
      const toClass = target.dataset.to;

      if (fromClass) target.classList.remove(fromClass);
      if (toClass) target.classList.add(toClass);
    });

    this.textFieldTargets.forEach(target => {
      target.value = "";
    });
  }

  show () {
    this.toggleDisplayTargets.forEach(target => {
      const fromClass = target.dataset.from;
      const toClass = target.dataset.to;

      if (toClass) target.classList.remove(toClass);
      if (fromClass) target.classList.add(fromClass);
    });
  }
}

window.recipientFieldKeychange = function recipientFieldKeychange (field, text) {
  if (event.key === "Enter" && event.target.value.length > 0) {
    addEmailAddress(field, text);
  } else if (event.key === "Backspace" && event.target.value === "") {
    deleteWithBackspace(field, text);
  }
};

window.removeEmailItem = function removeEmailItem () {
  const classToRemove = event.target.closest("span").classList[0];
  document.querySelectorAll(`.${classToRemove}`).forEach((element) => element.remove());
  if (document.querySelector(".cc-recipient") == null) {
    document.querySelector("#cc-wrapper").classList.add("hidden");
    document.querySelector("#cc-extra-recipients").classList.remove("hidden");
  }

  if (document.querySelector(".bcc-recipient") == null) {
    document.querySelector("#bcc-wrapper").classList.add("hidden");
    document.querySelector("#bcc-extra-recipients").classList.remove("hidden");
  }
};

window.showAdditionalRecipientFields = function showAdditionalRecipientFields (recipientType) {
  document.querySelector(`#${recipientType}-extra-recipients`).classList.toggle("hidden");
  const recipientWrapper = document.querySelector(`#${recipientType}-wrapper`);
  recipientWrapper.classList.toggle("hidden");
  const recipientInput = recipientWrapper.getElementsByTagName("input")[0];
  recipientInput.focus();
  recipientInput.select();
};

window.startComposingBody = function startComposingBody () {
  if (event.type === "click" || (event.key && event.key === "Tab")) {
    window.addRemainingEmails();
  }
};

window.addRemainingEmails = function addRemainingEmails () {
  const allInputFields = document.querySelectorAll(".recipients");
  allInputFields.forEach((field) => {
    if (field.value.length > 0) {
      switch (field.name) {
        case "to_recipients":
          addEmailList("to", field.value);
          break;
        case "cc_recipients":
          addEmailList("cc", field.value);
          break;
        default:
          addEmailList("bcc", field.value);
      }
      field.value = "";
    }
  });
};

function _editRecipientsClick () {
  const ccBccButtons = document.querySelectorAll(".extra-recipients");
  ccBccButtons.forEach((element) => element.classList.remove("hidden"));
  document.getElementById("to-recipients-wrapper").classList.remove("hidden");
  if (document.getElementById("cc-recipients-wrapper").childElementCount > 0) {
    ccBccButtons[0].classList.add("hidden");
    window.showAdditionalRecipientFields("cc");
  }
  if (document.getElementById("bcc-recipients-wrapper").childElementCount > 0) {
    ccBccButtons[1].classList.add("hidden");
    window.showAdditionalRecipientFields("bcc");
  }
}

function addEmailList (field, list) {
  list.split(" ").forEach((address) => { addEmailAddress(field, address); });
}

function addEmailAddress (field, text) {
  clearRecipientInput(event);
  const emailListWrapper = document.getElementById(`${field}-recipients-wrapper`);
  const fullEmailListWrapper = document.getElementById("all-recipients-wrapper");
  const emailBadge = `
    <span class="${field}-recipient-${emailListWrapper.childElementCount} ${field}-recipient inline-flex items-center py-0.5 pl-2 pr-0.5 mr-2 rounded-full ts-body-2 font-medium ${isValidEmail(text) ? "bg-blue-100 text-blue-700" : "bg-red-100 ts-text-error"}">
      ${text}
      <button type="button" onclick="removeEmailItem();" class="min-w-min mx-0.5 w-4 h-4 rounded-full inline-flex items-center justify-center
        ${isValidEmail(text)
? "text-blue-700 hover:bg-blue-300 hover:text-blue-900 focus:outline-none focus:bg-blue-400 focus:text-blue-900"
          : "text-red-600 hover:bg-red-200 hover:text-red-800 focus:outline-none focus:bg-red-300 focus:text-red-800"}">
        <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
          <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </button>
    </span>
  `;
  emailListWrapper.innerHTML += emailBadge;
  fullEmailListWrapper.innerHTML += emailBadge;
}

function clearRecipientInput (event) {
  if (event.target.id !== "email-message-area") { event.target.value = ""; }
}

function deleteWithBackspace (field) {
  const emails = document.querySelectorAll(`span[class^=${field}-recipient]`);
  if (emails.length > 0) {
    const emailToRemove = emails[emails.length - 1];
    document.querySelectorAll(`.${emailToRemove.classList[0]}`).forEach((element) => element.remove());
  }
}

function isValidEmail (email) {
  const filter = /^\s*[\w\-+_]+(\.[\w\-+_]+)*@[\w\-+_]+\.[\w\-+_]+(\.[\w\-+_]+)*\s*$/;
  return String(email).search(filter) !== -1;
}

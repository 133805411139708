import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "criteriaForm",
    "aboutContent",
    "ndaForm",
    "emailNdaNotification",
    "shareNdaNotification",
    "pauseNdaNotification",
    "ndaSelectSubmit",
    "errorNotification",
  ];

  showAbout (_event) {
    this.criteriaFormTarget.classList.add("hidden");
    this.aboutContentTarget.classList.remove("hidden");
  }

  showNdaForm (_event) {
    this.aboutContentTarget.classList.add("hidden");
    this.ndaFormTarget.classList.remove("hidden");
  }

  redirectOrDisplayNda (event) {
    const data = event.detail[0];

    if (data.type === "redirect") {
      window.location.href = data.value;
    } else if (data.type === "share") {
      this.shareNdaNotificationTarget.classList.remove("hidden");
      this.pauseNdaNotificationTarget.classList.add("hidden");
    } else if (data.type === "pause") {
      this.shareNdaNotificationTarget.classList.add("hidden");
      this.pauseNdaNotificationTarget.classList.remove("hidden");
    }

    this.ndaSelectSubmitTarget.classList.add("hidden");
  }

  toggleNdaCta (_event) {
    this.ndaSelectSubmitTarget.classList.remove("hidden");
  }

  showErrorNotification (_event) {
    this.errorNotificationTarget.classList.remove("hidden");
  }
}

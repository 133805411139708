import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["year", "accountingBasis"];

  connect () {
    this.preselectAccountingBasis();
  }

  preselectAccountingBasis () {
    if (this.yearTarget.value === "Not a year") {
      this.accountingBasisTarget.value = "";
      this.accountingBasisTarget.disabled = true;
    } else if (this.accountingBasisTarget.disabled) {
      this.accountingBasisTarget.value = "ACTUAL";
      this.accountingBasisTarget.disabled = false;
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tasksList", "taskActions", "checked", "unchecked", "description", "show", "hide", "task", "completedTask"];

  showActions (event) {
    const rowIndex = this.taskTargets.indexOf(event.currentTarget);
    const taskActions = this.taskActionsTargets[rowIndex];

    taskActions.classList.remove("invisible");
  }

  hideActions (event) {
    const rowIndex = this.taskTargets.indexOf(event.currentTarget);
    const taskActions = this.taskActionsTargets[rowIndex];

    taskActions.classList.add("invisible");
  }

  completeTask (event) {
    const rowIndex = this.uncheckedTargets.indexOf(event.currentTarget);
    const task = this.taskTargets[rowIndex].firstChild;

    this.toggleCheckbox(event.currentTarget, this.checkedTargets[rowIndex]);
    task.classList.add("bg-gray-100");
    this.descriptionTargets[rowIndex].classList.add("line-through");
    this.fadeOut(task, event.currentTarget.parentElement.href);
  }

  uncompleteTask (event) {
    const rowIndex = this.checkedTargets.indexOf(event.currentTarget);
    const task = this.taskTargets[rowIndex].firstChild;

    this.toggleCheckbox(this.uncheckedTargets[rowIndex], event.currentTarget);
    task.classList.remove("bg-gray-100");
    this.descriptionTargets[rowIndex].classList.remove("line-through");
    this.fadeOut(task, event.currentTarget.parentElement.href);
  }

  toggleCheckbox (uncheckedBox, checkedBox) {
    uncheckedBox.classList.toggle("hidden");
    checkedBox.classList.toggle("hidden");
  }

  unflagTask (event) {
    this.fadeOut(event.currentTarget, event.currentTarget.href);
  }

  unscheduleTask (event) {
    this.fadeOut(event.currentTarget.parentElement, event.currentTarget.href);
  }

  fadeOut (target, url) {
    event.preventDefault();
    event.stopPropagation();
    target.classList.add("fade-out");
    this.updateTaskAfterTimeout(url);
  }

  updateTaskAfterTimeout (url, timeout = 1000) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() =>
      Teamshares.Rails.ajax({
        type: "PATCH",
        dataType: "script",
        url,
      }), timeout);
  }

  showCompleted () {
    this.showTarget.classList.add("hidden");
    this.hideTarget.classList.remove("hidden");
    this.tasksListTarget.classList.add("border", "border-gray-300");

    this.toggleTasks();
  }

  hideCompleted (event) {
    this.showTarget.classList.remove("hidden");
    this.hideTarget.classList.add("hidden");

    if (event.params.numActive === 0) {
      this.tasksListTarget.classList.remove("border", "border-gray-300");
    }
    this.toggleTasks();
  }

  toggleTasks () {
    this.completedTaskTargets.forEach((task) => task.classList.toggle("hidden"));
  }
}

import { Turbo } from "@hotwired/turbo-rails";

const attachmentMap = {
  "Signed NDA": "signed_nda",
  "Buyer Profile": "buyer_profile",
};

window.prepareForm = function prepareForm (emailPath) {
  ["click", "keyup"].forEach(evt =>
    document.addEventListener(evt, window.checkFormDisabled),
  );

  const sendEmailForm = document.querySelector("#email-form");
  if (!sendEmailForm.getAttribute("data-event-submit")) {
    sendEmailForm.addEventListener("submit", function (e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.submitter.value) {
        window.toggleScheduleEmailDropdown();
        toggleFormDisabled();
        document.getElementById("email-error").classList.add("hidden");
        sendEmail(e.submitter.value, emailPath);
      } else {
        const confirmation = window.confirm("Do you really want to send?");

        if (confirmation) {
          toggleFormDisabled();
          document.getElementById("email-error").classList.add("hidden");
          sendEmail(e.submitter.value, emailPath);
        }
      }
    });
    sendEmailForm.setAttribute("data-event-submit", "true");
  }
};

window.toggleForm = function toggleForm () {
  document.querySelector("#new-message-button").classList.toggle("hidden");
  document.querySelector("#send-email-section").classList.toggle("hidden");
};

window.clearForm = function clearForm (requireConfirm) {
  if (!requireConfirm || confirm("Are you sure you want to discard this draft?")) {
    document.querySelector("#email-form").reset();
    resetRecipients();
    window.toggleForm();
    const files = document.querySelector("#attachments").dropzone.getAcceptedFiles();

    files.forEach(file =>
      window.myDropzone.removeFile(file),
    );
    Array.from(document.querySelector("#snippet-attachments").children).forEach(file => file.remove());
  }
};

window.setEmailFields = function setEmailFields (content, attachments, ndaUrl, signature) {
  const currentText = document.querySelector("#email-message-area").value.replace(signature, "");
  document.querySelector("#email-message-area").value = currentText + content + signature;
  let attachmentLink;
  attachments.forEach(attachment => {
    attachmentLink = attachment === "signed-nda" ? ndaUrl : "";
    window.appendFile({ name: attachment, url: attachmentLink }, true);
  });
  window.toggleSnippetDropdown();
};

window.removeFile = function removeFile (file) {
  return function () {
    window.myDropzone.removeFile(file);
    window.checkFormDisabled();
  };
};

window.deleteSnippetFile = function deleteSnippetFile () {
  return function () {
    event.target.closest("div").remove();
  };
};

window.moveAttachments = function moveAttachments () {
  const filePreview = document.querySelectorAll(".dz-preview");
  document.querySelector("#upload-area").appendChild(filePreview[filePreview.length - 1]);
};

window.addDynamicTag = function addDynamicTag (dropdown) {
  document.querySelector("#email-snippet-content").value += dropdown.value;
  dropdown.selectedIndex = null;
};

window.addDynamicAttachment = function addDynamicAttachment (dropdown) {
  const selectedAttachment = attachmentMap[dropdown.value];
  const attachmentHiddenField = document.getElementById(`email_snippet_${selectedAttachment}`);

  if (attachmentHiddenField.value === "true") {
    alert(`Email snippet already has ${dropdown.value} as an attachment`);
    dropdown.selectedIndex = null;
    return;
  }

  const attachmentRow = `
    <div class="flex items-center">
      <svg class="h-5 w-5 inline" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" fill-rule="evenodd"></path></svg>
      <span class="ts-body-2 m-2 ts-text-subdued">${dropdown.value}</span>
      <button type="button" data-test-id="delete-snippet-attachment" onclick="removeSnippetAttachment('${dropdown.value}');" class="min-w-min w-4 h-4 rounded-full inline-flex items-center justify-center text-gray-700 hover:bg-gray-300 hover:text-gray-900 focus:outline-none focus:bg-gray-400 focus:text-gray-900">
        <svg width="8" height="9" stroke="currentColor" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1.25L7 7.75M1 7.75L7 1.25L1 7.75Z" stroke="#6D7176" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>

  `;
  attachmentHiddenField.value = "true";
  const attachmentListWrapper = document.getElementById("attachments-wrapper");
  attachmentListWrapper.innerHTML += attachmentRow;
  dropdown.selectedIndex = null;
};

window.removeSnippetAttachment = function removeSnippetAttachment (attachmentName) {
  event.target.closest("div").remove();
  const attachmentInputField = document.getElementById(`email_snippet_${attachmentMap[attachmentName]}`);
  attachmentInputField.value = "false";
};

window.appendFile = function appendFile (file, isSnippetAttachment) {
  let newFile;
  if (isSnippetAttachment) {
    const container = document.querySelector("#snippet-attachments");
    newFile = document.createElement("div");
    newFile.classList.add("mb-2");
    container.appendChild(newFile);
  } else {
    const allPreviews = document.querySelectorAll(".dz-preview");
    newFile = allPreviews[allPreviews.length - 1];
  }
  addFile(newFile, isSnippetAttachment, file);
};

function addFile (parentContainer, isSnippetAttachment, file) {
  const filename = file.url ? `<a href=${file.url} class="hover:underline" target="_blank">${file.name}</a>` : file.name;
  parentContainer.innerHTML = "<svg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5 inline mb-1 mr-1' viewBox='0 0 20 20' fill='currentColor'><path fill-rule='evenodd' d='M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z' clip-rule='evenodd' /></svg>" + filename;
  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  const path = document.createElementNS("http://www.w3.org/2000/svg", "path");

  svg.setAttribute("viewbox", "0 0 20 20");
  svg.setAttribute("class", "h-5 w-5 inline cursor-pointer ml-2 mb-1 text-gray-500");
  svg.setAttribute("fill", "currentColor");

  path.setAttribute("fill-rule", "evenodd");
  path.setAttribute("clip-rule", "evenodd");
  path.setAttribute("d", "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z");

  svg.appendChild(path);
  svg.onclick = isSnippetAttachment ? window.deleteSnippetFile() : window.removeFile(file);
  parentContainer.appendChild(svg);
  window.checkFormDisabled();
}

window.checkFormDisabled = function checkFormDisabled (signature) {
  const emailButtons = document.querySelectorAll("#send-email-button, #schedule-email-button");

  const emailBlank = document.querySelector("#email-message-area").value.replace(signature, "") === "";
  const filesBlank = document.querySelector("#attachments").dropzone.getAcceptedFiles().length === 0;
  const toBlank = document.querySelector("#to-recipients-wrapper").textContent.trim() === "";

  if ((emailBlank && filesBlank) || toBlank) {
    document.querySelector("#airplane-logo").classList.remove("fill-white");
    document.querySelector("#schedule-chevron").classList.remove("stroke-white");
    emailButtons.forEach(emailButton => {
      emailButton.disabled = true;
      emailButton.style.cursor = "default";
    });
  } else {
    document.querySelector("#airplane-logo").classList.add("fill-white");
    document.querySelector("#schedule-chevron").classList.add("stroke-white");
    emailButtons.forEach(emailButton => {
      emailButton.disabled = false;
      emailButton.style.cursor = "pointer";
    });
  }
};

window.toggleSnippetDropdown = function toggleSnippetDropdown () {
  document.querySelector("#snippet-dropdown").classList.toggle("hidden");
};

window.toggleEmailOptionsDropdown = function toggleEmailOptionsDropdown () {
  document.querySelector("#email-options-dropdown").classList.toggle("hidden");
};

window.toggleScheduleEmailDropdown = function toggleScheduleEmailDropdown () {
  document.querySelector("#schedule-email-dropdown").classList.toggle("hidden");
};

function getRecipients (type) {
  const recipientWrapper = document.getElementById(`${type}-recipients-wrapper`).children;
  const emails = Array.prototype.slice.call(recipientWrapper);
  return emails.map(email => email.innerText.trim());
}

function getFormValues (sendAt) {
  const formData = new FormData(document.querySelector("#email-form"));

  const dropzoneFiles = document.querySelector("#attachments").dropzone.getAcceptedFiles();

  dropzoneFiles.forEach(file =>
    formData.append("email_attachments[]", file),
  );

  Array.from(document.querySelector("#snippet-attachments").children).forEach(filename => {
    formData.append("snippet_attachments[]", filename.innerText);
  });

  if (sendAt) {
    formData.append("email_draft[to_recipients]", getRecipients("to"));
    formData.append("email_draft[cc_recipients]", getRecipients("cc"));
    formData.append("email_draft[bcc_recipients]", getRecipients("bcc"));
    formData.append("email_draft[deliver_at]", sendAt);
  } else {
    formData.append("email[to_recipients]", getRecipients("to"));
    formData.append("email[cc_recipients]", getRecipients("cc"));
    formData.append("email[bcc_recipients]", getRecipients("bcc"));
  }

  window.formData = formData;

  return formData;
}

function toggleFormDisabled () {
  const scheduleEmailButton = document.querySelector("#schedule-email-button");

  document.querySelector("#send-email-button").classList.toggle("hidden");
  document.querySelector("#sending-button").classList.toggle("hidden");
  scheduleEmailButton.disabled = !scheduleEmailButton.disabled;
}

function sendEmailForm (emailPath) {
  const emailForm = document.querySelector("#email-form");
  const path = emailPath;

  if (!window.formApplied) {
    window.formApplied = true;
    Array.from(emailForm.elements).forEach((input) => {
      if (input.name !== "authenticity_token") {
        const inputName = input.name;
        input.name = "email[" + inputName + "]";
      }
    });
  }

  return { path };
}

function scheduledEmailForm (emailPath) {
  const emailForm = document.querySelector("#email-form");
  const path = emailPath;

  if (!window.formApplied) {
    window.formApplied = true;
    Array.from(emailForm.elements).forEach((input) => {
      if (input.name !== "authenticity_token") {
        const inputName = input.name;
        input.name = "email_draft[" + inputName + "]";
      }
    });
  }

  return { path };
}

function sendEmail (sendAt, emailPath) {
  const form = sendAt ? scheduledEmailForm(emailPath) : sendEmailForm(emailPath);

  Teamshares.Rails.ajax({
    type: "POST",
    url: form.path,
    data: getFormValues(sendAt),
    success: function () {
      window.clearForm(false);
      toggleFormDisabled();
    },
    error: function (data) {
      Turbo.renderStreamMessage(data);
      toggleFormDisabled();
    },
  });
}

function resetRecipients () {
  const recipientList = ["all-recipients-wrapper", "to-recipients-wrapper", "cc-recipients-wrapper", "bcc-recipients-wrapper"];
  recipientList.forEach((recipient) => {
    document.getElementById(recipient).innerHTML = "";
  });
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  hide () {
    this.inputTarget.style.display = "none";
  }

  show () {
    this.inputTarget.style.display = "block";
  }
}

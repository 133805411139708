import { Controller } from "@hotwired/stimulus";

let timeout;
export default class extends Controller {
  static targets = ["tooltip"];

  show () {
    clearTimeout(timeout);
    timeout = setTimeout(() => this.tooltipTarget.classList.remove("hidden"), 500);
  }

  hide () {
    clearTimeout(timeout);
    setTimeout(() => this.tooltipTarget.classList.add("hidden"), 250);
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["text"];

  openUrlInNewTab (event) {
    const url = this.textTarget.value;
    const re = /^(http|https):\/\//i;
    if (re.test(url)) {
      window.open(url, "Supporting Documents");
    } else {
      window.open("https://" + url, "Supporting Documents");
    }
    event.preventDefault();
  }
}

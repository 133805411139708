document.addEventListener("DOMContentLoaded", function (_event) {
  const taxFieldSelects = document.querySelectorAll(".tax-prediction-field-evaluation");
  if (taxFieldSelects.length) {
    taxFieldSelects.forEach(input => {
      input.addEventListener("change", function (event) {
        if (event.target.value === "incorrect") {
          event.target.closest(".tax-field-entry").querySelector(".correction-entry").classList.remove("hidden");
          event.target.closest(".tax-field-entry").querySelector(".correction-entry").classList.add("block");
        } else {
          event.target.closest(".tax-field-entry").querySelector(".correction-entry").classList.add("hidden");
          event.target.closest(".tax-field-entry").querySelector(".correction-entry").classList.remove("block");
        }
      });
    });
  }
});

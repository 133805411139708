import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "save"];

  async connect () {
    if (await this.isFeatureFlagEnabled("stricter-form-control")) {
      this.form = this.element instanceof HTMLFormElement ? this.element : this.element.querySelector("form");
      if (this.form) {
        this.originalFormValues = this.serializeForm();

        this.form.addEventListener("click", this.checkFormState.bind(this));
        this.form.addEventListener("change", this.checkFormState.bind(this));
        this.form.addEventListener("google-address:change", this.checkFormState.bind(this));
        this.form.addEventListener("google-picker:change", this.checkFormState.bind(this));
        this.form.addEventListener("input", this.checkFormState.bind(this));
        this.form.addEventListener("keyup", this.checkFormState.bind(this));
        this.form.addEventListener("reset", this.checkFormState.bind(this));
        this.form.addEventListener("submit", this.handleFormSubmit.bind(this));

        this.handleBeforeUnloadBind = this.handleBeforeUnload.bind(this);
        window.addEventListener("beforeunload", this.handleBeforeUnloadBind);

        this.checkFormState();
      }
    }
  }

  async isFeatureFlagEnabled (featureFlagName) {
    try {
      const response = await fetch(`/admin/api/flipper/${featureFlagName}`);
      const result = await response.json();
      return result.enabled;
    } catch (error) {
      console.error("Error fetching feature flag state:", error);
      throw error;
    }
  }

  disconnect () {
    window.removeEventListener("beforeunload", this.handleBeforeUnloadBind);
  }

  formTargetConnected () {
    // Form target is necessary when form is lazy loaded via Turbo
    this.connect();
  }

  checkFormState () {
    this.saveTarget.disabled = !(this.isFormChanged() && this.areVisibleRequiredFieldsFilled());
  }

  isFormChanged () {
    const currentFormValues = this.serializeForm();
    return JSON.stringify(this.originalFormValues) !== JSON.stringify(currentFormValues);
  }

  areVisibleRequiredFieldsFilled () {
    const requiredFields = this.element.querySelectorAll("[required]:not(.trix-input)");
    return Array.from(requiredFields).every((field) => !this.isVisible(field) || (field.value && field.value.length));
  }

  isVisible (element) {
    return !element.classList.contains("hidden") && !element.closest(".hidden");
  }

  serializeForm () {
    const formData = new FormData(this.form);
    const serialized = {};

    formData.forEach((value, key) => {
      serialized[key] = value;
    });

    return serialized;
  }

  handleFormSubmit () {
    this.formSubmittedValue = true;
  }

  handleBeforeUnload (event) {
    if (this.isFormChanged() && !this.formSubmittedValue) {
      const confirmationMessage = "You have unsaved changes. Are you sure you want to leave?";
      (event || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hiddenField"];

  updateHiddenField () {
    const hiddenField = this.hiddenFieldTarget.querySelector("input");
    hiddenField.value = "true";
  }
}

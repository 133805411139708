import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect () {
    const button = this.buttonTarget;
    this.buttonTarget.addEventListener("click", function (event) {
      if (event.target === button && button.innerHTML === "Show all steps") {
        button.innerHTML = "Hide all steps";
      } else {
        button.innerHTML = "Show all steps";
      }
    });
  }
}

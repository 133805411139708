import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect () {
    this.addDash();
  }

  addDash () {
    const numWithDash = this.inputTarget.value.replace(/\D/g, "").replace(/^(\d{2})(\d{1})/, "$1-$2");
    this.inputTarget.value = numWithDash;
  }
}

import { Controller } from "@hotwired/stimulus";
import Tribute from "tributejs";
import Trix from "trix";

export default class extends Controller {
  connect () {
    this.editor = this.element.editor;
    this.initializeTribute();
  }

  disconnect () {
    this.tribute.detach(this.element);
  }

  initializeTribute () {
    this.tribute = new Tribute({
      trigger: "@",
      allowSpaces: true,
      lookup: "full_name",
      menuItemLimit: 10,
      menuItemTemplate: item => item.original.menu_item,
      values: this.fetchUsers,
    });
    this.tribute.attach(this.element);
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this);
    this.element.addEventListener("tribute-replaced", this.replaced);
  }

  fetchUsers (text, callback) {
    fetch(`/admin/user_mentions.json?query=${text}`)
      .then(response => response.json())
      .then(users => callback(users))
      .catch(_error => callback([]));
  }

  replaced (e) {
    const mention = e.detail.item.original;
    const attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content,
      caption: mention.slack_mention,
    });
    this.editor.insertAttachment(attachment);
    this.editor.insertString(" ");
  }

  _pasteHtml (html, startPos, endPos) {
    const position = this.editor.getPosition();
    this.editor.setSelectedRange([position - (endPos - startPos), position]);
    this.editor.deleteInDirection("backward");
  }
}

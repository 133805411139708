import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  autoGrow () {
    const commentTextArea = document.querySelectorAll("#comment-text-area");
    commentTextArea.forEach(field => {
      field.style.height = (field.scrollHeight) + "px";
    });
  }
}

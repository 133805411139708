import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["datepicker", "caption", "errorText", "saveButton"];

  validateInput () {
    this.day = new Date(this.datepickerTarget.value).getDay();

    if ([5, 6].includes(this.day) || event.params.teamsharesHolidays.includes(this.datepickerTarget.value)) {
      this.datepickerTarget.classList.add("error");
      this.captionTarget.classList.add("hidden");
      this.errorTextTarget.classList.remove("hidden");
      this.saveButtonTarget.setAttribute("disabled", true);
    } else {
      this.datepickerTarget.classList.remove("error");
      this.captionTarget.classList.remove("hidden");
      this.errorTextTarget.classList.add("hidden");
      this.saveButtonTarget.removeAttribute("disabled");
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  addDecimal (event) {
    const input = event.target;
    input.addEventListener("keyup", function () {
      const numWithDecimal = input.value.replace(/\D/g, "").replace(/\B(?=(\d{2})+(?!\d))/g, ".").replace(/^(.{6}).*$/, "$1");
      input.value = numWithDecimal;
    });
  }
}

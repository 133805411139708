import "@webcomponents/custom-elements";
import "@github/clipboard-copy-element";

import { init as initCommandBar } from "commandbar";
import Teamshares from "@teamshares/design-system/rails-js";

import "whatwg-fetch";
import Dropzone from "dropzone";

import "trix";
import "@rails/actiontext";

import "./channels";

import "./includes/numberFormat";
import "./includes/taxFieldEntry";
import "./includes/termsModeling";
import "./includes/admin/retainUnsavedNotes";
import "./includes/admin/leadBrokerUpdate";
import "./includes/admin/emails/sendEmails";
import "./includes/admin/emails/editRecipients";
import "./includes/brokerDocumentUpload";
import "./includes/admin/twilioCall";
import "./includes/admin/algolia";

import { datadogRum } from "@datadog/browser-rum";

import MicroModal from "micromodal";
window.MicroModal = MicroModal;

// Initialization of the command bar with our organization ID
initCommandBar("bcbf071e");

Teamshares.start({ disableTurboSessionDrive: true });

Dropzone.autoDiscover = false;

if (window._ddSessionReplayEnabled === "true") {
  datadogRum.init({
    applicationId: window._ddApplicationId,
    clientToken: window._ddClientToken,
    site: window._ddSite,
    service: window._ddService,
    env: window._ddEnvironment,
    version: Teamshares.deployed_app_sha,
    sessionSampleRate: parseInt(window._ddSessionSampleRate),
    premiumSampleRate: parseInt(window._ddPremiumSampleRate),
    trackUserInteractions: !!window._ddTrackUserInteractions,
    defaultPrivacyLevel: window._ddPrivacyLevel,
  });

  datadogRum.startSessionReplayRecording();
}

// This handles the case of missing turbo-frame targets by just reloading the page
// (which turbo did automatically prior to 7.3.0)
document.addEventListener("turbo:frame-missing", (event) => {
  const { detail: { response, visit } } = event;
  console.warn("Missing turbo-frame target:", event.target);
  event.preventDefault();
  visit(response.url);
});

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "countBadge", "panel"];

  initialize () {
    this.showTab(0);
  }

  changeTab (event) {
    this.showTab(this.tabTargets.indexOf(event.currentTarget));
  }

  showTab (index) {
    this.tabTargets.forEach((tab, i) => {
      const panel = this.panelTargets[i];
      if (i === index) {
        tab.classList.add("ts-tab-active");
        tab.classList.remove("ts-tab");
        panel.classList.remove("hidden");
      } else {
        tab.classList.remove("ts-tab-active");
        tab.classList.add("ts-tab");
        panel.classList.add("hidden");
      }
    });

    this.countBadgeTargets.forEach((badge, i) => {
      if (i === index) {
        badge.classList.add("ts-tab-badge-active");
        badge.classList.remove("ts-tab-badge");
      } else {
        badge.classList.remove("ts-tab-badge-active");
        badge.classList.add("ts-tab-badge");
      }
    });
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["final"];

  copy (event) {
    this.finalTarget.value = event.currentTarget.getAttribute("data-value");
    event.preventDefault();
  }
}

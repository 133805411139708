import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["skip", "form"];

  skip () {
    this.skipTarget.value = "1";
    this.formTarget.submit();
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["highlightsSection", "card", "prediction", "input", "categorySelect", "subcategorySelect"];
  static values = {
    title: String,
  };

  connect () {
    if (this.titleValue === "owner") {
      this.alignPredictionsToOwnerCard();
    }

    this.togglePredictionSection();

    if (this.highlightsSectionTarget.open) {
      this.highlightsSectionTarget.scrollIntoView(true);

      // trix-editor is unable to focus on initial connect, set small timeout to focus
      if (this.inputTargets[0].tagName === "TRIX-EDITOR") setTimeout(() => this.inputTargets[0].focus(), 20);
    }
  }

  alignPredictionsToOwnerCard () {
    const predictionSection = this.predictionTarget.parentElement.parentElement;
    const highlightTop = this.highlightsSectionTarget.getBoundingClientRect().top;
    const cardTop = this.cardTarget.getBoundingClientRect().top;
    const headerHeight = cardTop - highlightTop;
    predictionSection.style.marginTop = `${headerHeight}px`;

    // set small timeout to get accurate offsetHeight
    setTimeout(() => {
      const cardHeight = this.cardTarget.offsetHeight;

      this.predictionTargets.forEach((prediction) => {
        const predictionGroup = prediction.parentElement;
        if (predictionGroup.style.minHeight !== `${cardHeight}px`) {
          predictionGroup.style.minHeight = `${cardHeight}px`;
        }
      });
    }, 10);
  }

  togglePredictionSection (event) {
    // shoelace bug: all sl-show and sl-hide is triggering this function no matter what element the data-action is specified on
    // this is causing sl-select to trigger this function when it should not
    // prediction section should only toggle on connect or when clicking on the highlights section
    if (event !== undefined && event.target !== this.highlightsSectionTarget) return;

    if (this.highlightsSectionTarget.open) {
      this.inputTargets[0].focus();
    }
    this.predictionTargets.forEach((prediction) => { prediction.hidden = !this.highlightsSectionTarget.open; });
  }

  togglePrediction (event) {
    this.predictionTargets.forEach((prediction) => {
      if (/focus|show/.test(event.type) && prediction.dataset.field === event.currentTarget.dataset.field) {
        prediction.open = true;
        prediction.classList.add("with-shadow");
      } else {
        prediction.open = false;
        prediction.classList.remove("with-shadow");
      }
    });
  }

  removeShadow (event) {
    event.currentTarget.classList.remove("with-shadow");
  }

  addPrediction (event) {
    this.inputTargets.forEach((input) => {
      if (input.dataset.field === event.currentTarget.dataset.field) input.value = event.currentTarget.dataset.predictionValue;
    });

    if (event.currentTarget.dataset.field === "industry") this.addIndustryPrediction(event);
  }

  addIndustryPrediction (event) {
    let parent;
    for (const subcategory of this.subcategorySelectTarget.children) {
      if (subcategory.textContent === event.currentTarget.dataset.predictionValue) {
        this.subcategorySelectTarget.disabled = false;
        this.subcategorySelectTarget.value = subcategory.value;

        parent = subcategory.dataset.parent;
        this.categorySelectTarget.value = parent;
        break;
      }
    }
    for (const subcategory of this.subcategorySelectTarget.children) subcategory.hidden = subcategory.dataset.parent !== parent;
  }

  restackOwnerPredictions (event) {
    const parent = event.currentTarget.closest(event.currentTarget.dataset.parentItem);
    const fields = [];

    parent.querySelectorAll("sl-input, trix-editor").forEach((input) => fields.push(input.dataset.field));
    this.predictionTargets.forEach((prediction) => { if (fields.includes(prediction.dataset.field)) prediction.remove(); });
    this.predictionTargets.forEach((prediction, idx) => {
      prediction.querySelector("div[slot=summary]").innerHTML = prediction.querySelector("div[slot=summary]").innerHTML.replace(/#\d+/, `#${Math.ceil((idx + 1) / 2)}`);
    });
  }
}

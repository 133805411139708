import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dropdown1", "dropdown2", "dropdown3"];

  toggle (element1, element2, element3) {
    element1.classList.add("block");
    element1.classList.remove("hidden");

    element2.classList.add("hidden");
    element3.classList.add("hidden");
  }

  displayDropdown1 () {
    this.toggle(this.dropdown1Target, this.dropdown2Target, this.dropdown3Target);
  }

  displayDropdown2 () {
    this.toggle(this.dropdown2Target, this.dropdown3Target, this.dropdown1Target);
  }

  displayDropdown3 () {
    this.toggle(this.dropdown3Target, this.dropdown1Target, this.dropdown2Target);
  }
}

// Documentation: https://www.twilio.com/docs/voice/sdks/javascript/twiliodevice#instantiate-a-device
import { Device } from "@twilio/voice-sdk";

let device;
let call;

window.getTwilioToken = function getTwilioToken () {
  Teamshares.Rails.ajax({
    type: "POST",
    dataType: "script",
    url: "/admin/outbound-calls/token",
    processData: false,
    contentType: false,
    success: function (response) {
      const token = response.token;
      device = new Device(token);
    },
    error: function (_response) {
      // TODO: handle error here
    },
  });
};

window.initiateCall = async function initiateCall (phoneNumber, callId) {
  call = await device.connect({
    params: {
      To: phoneNumber,
      callId,
    },
  });

  call.on("disconnect", function () {
    document.getElementById("call-modal").classList.toggle("hidden");
    Teamshares.Rails.ajax({
      type: "PATCH",
      dataType: "script",
      url: `/admin/outbound_calls/${callId}?number=${phoneNumber}`,
    });
  });
};

window.endCall = function endCall () {
  device.disconnectAll();
};

window.handleMute = function handleMute (isMuted) {
  call.mute(isMuted);
  document.getElementById("unmute-button").classList.toggle("hidden");
  document.getElementById("mute-button").classList.toggle("hidden");
};

window.sendDigit = function sendDigit (digit) {
  call.sendDigits(digit);
};

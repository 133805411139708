import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  connect () {
    this.myDropzone = new Dropzone(this.element, { headers: { Accept: "text/vnd.turbo-stream.html, text/html, application/xhtml+xml" } });
    this.myDropzone.on("success", (file, response) => {
      // Dropzone does not automatically respond to Turbo Stream responses, so we need to manually render the response
      Turbo.renderStreamMessage(response);
    });
  }
}

window.numberWithCommas = function (numString) {
  return "$" + numString.replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/\$/g, "");
};

document.addEventListener("DOMContentLoaded", function (_event) {
  const currencyInputs = document.querySelectorAll(".currency-input");
  currencyInputs.forEach(input => {
    input.addEventListener("keyup", function (event) {
      const formatted = window.numberWithCommas(event.currentTarget.value.replace(/,/g, ""));
      event.currentTarget.value = formatted === "0" ? "" : formatted;
    });

    input.form.addEventListener("submit", function (_event) {
      currencyInputs.forEach(input => { input.value = input.value.replace(/,/g, "").replace("$", ""); });
    });
  });
});

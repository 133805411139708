document.addEventListener("DOMContentLoaded", function (_event) {
  const mutiplierInputs = document.querySelectorAll(".edbita_multiplier");

  mutiplierInputs.forEach(input => {
    input.addEventListener("keyup", function (event) {
      if (event.keyCode === 38) {
        event.currentTarget.value = (parseFloat(event.currentTarget.value) + 0.1).toFixed(1);
      }
      if (event.keyCode === 40) {
        event.currentTarget.value = (parseFloat(event.currentTarget.value) - 0.1).toFixed(1);
      }

      const multiple = parseFloat(event.currentTarget.value);
      const teamsharesEbitda = parseInt(document.getElementById("teamshares_ebitda_median").value);
      document.getElementById(event.currentTarget.getAttribute("data-bound-control")).textContent = window.numberWithCommas(parseInt(multiple * teamsharesEbitda).toString());
    });
  });
});

import { searchClient } from "@algolia/client-search";
const client = searchClient("C1BGFS3NLW", "b175f21c5432cf01757d129920cbc7fe");

window.onSearch = async function onSearch (query) {
  const cleanedLeads = [];
  const response = await client.searchSingleIndex({
    indexName: `Lead_${getCurrentEnv()}`,
    searchParams: { query },
  });

  response.hits.forEach(lead => {
    const leadLabel = lead.company_name ? lead.company_name : lead.listing_title;
    if (leadLabel) {
      cleanedLeads.push({
        label: leadLabel,
        id: lead.objectID,
      });
    }
  });

  return cleanedLeads;
};

function getCurrentEnv () {
  return document.getElementsByTagName("body")[0].dataset.env;
}

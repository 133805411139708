import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["all", "name", "status"];

  submitForKeydown (keyboardEvent) {
    if (["Enter", "Return"].includes(keyboardEvent.key)) {
      this.submit();
    }
  }

  submit () {
    this.setAllLeads();
    this.element.submit();
  }

  setAllLeads () {
    if (this.hasStatus() || this.nameTarget.value) {
      this.allTarget.value = "true";
    }
  }

  hasStatus () {
    return this.statusTarget.value && this.statusTarget.value !== "all";
  }
}

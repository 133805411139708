import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["supportform"];

  show (event) {
    event.currentTarget.classList.add("hidden");

    if (document.getElementById("supporting-docs.existing")) {
      document.getElementById("supporting-docs.existing").classList.add("hidden");
    }

    this.supportformTarget.classList.remove("hidden");
  }
}

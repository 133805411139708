import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggleFinalizationDeadline"];

  toggle (event) {
    if (event.currentTarget.value) {
      this.toggleFinalizationDeadlineTarget.disabled = false;
    } else {
      this.toggleFinalizationDeadlineTarget.disabled = true;
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["enable"];

  toggle (event) {
    if (event.currentTarget.value) {
      this.enableTarget.disabled = false;
    } else {
      this.enableTarget.disabled = true;
    }
  }
}

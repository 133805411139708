import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"];

  openModal () {
    this.modalTarget.show();
  }

  closeModal () {
    this.modalTarget.hide();
    this.clearForm();
  }

  clearForm () {
    this.form = this.element.querySelector("form");
    if (this.form) {
      this.form.reset();
    }
  }
}

import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";

export default class extends Controller {
  static targets = ["chart"];

  connect () {
    this.graph = new Chart(this.canvasContext(), {
      data: {
        labels: this.labels(),
        datasets: [{
          type: "bar",
          label: "Revenue",
          data: this.values().map(attr => attr.net_revenue),
          borderColor: "rgb(139,92,246)",
          backgroundColor: "rgba(204,251,241)",
          hoverOffset: 4,
          order: 2,
        },
        {
          type: "bar",
          label: "EBITDA",
          data: this.values().map(attr => attr.ebitda),
          borderColor: "rgb(231,216,448,962)",
          backgroundColor: "rgba(45,212,191)",
          hoverOffset: 4,
          order: 2,
        },
        {
          type: "bar",
          label: "SDE",
          data: this.values().map(attr => attr.owner_stated_sde),
          borderColor: "rgb(246,175,71)",
          backgroundColor: "rgba(13,148,136)",
          hoverOffset: 4,
          order: 2,
        },
        {
          type: "line",
          label: "Margin",
          data: this.values().map(attr => attr.margin),
          fill: false,
          borderColor: "rgb(59, 116, 252)",
          yAxisID: "y1",
          hoverOffset: 4,
          order: 1,
        }],
      },
      options: {
        plugins: {
          legend: {
            position: "top",
            align: "start",
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            type: "linear",
            display: true,
            position: "left",
          },
          y1: {
            type: "linear",
            display: true,
            position: "right",
            min: 0,
            suggestedMax: 0.3,
            grid: {
              drawOnChartArea: false,
            },
            ticks: {
              format: {
                style: "percent",
              },
            },
          },
        },
      },
      plugins: [{
        beforeInit: (chart) => {
          const originalFit = chart.legend.fit;

          chart.legend.fit = function fit () {
            originalFit.bind(chart.legend)();
            this.height += 35;
          };
        },
      }],
    });
  }

  canvasContext () {
    return this.chartTarget.getContext("2d");
  }

  labels () {
    this._labels = this._labels || Object.keys(this.dataset());
    return this._labels;
  }

  values () {
    this._values = this._values || Object.values(this.dataset());
    return this._values;
  }

  dataset () {
    this._dataset = this._dataset || JSON.parse(this.data.get("dataset"));
    return this._dataset;
  }
}

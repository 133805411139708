import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";

export default class extends Controller {
  static targets = ["chart"];

  connect () {
    this.graph = new Chart(this.canvasContext(), {
      type: "doughnut",
      data: {
        labels: this.labels(),
        datasets: [{
          label: this.data.get("dataset-label"),
          data: this.values(),
          hoverOffset: 4,
        }],
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: this.data.get("title"),
          },
          legend: {
            position: "left",
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      plugins: [{
        beforeDraw: (chart, _args, _options) => {
          const width = chart.width - chart.legend.width;
          const height = chart.height;
          const ctx = chart.ctx;
          const fontSize = (height / 160).toFixed(2);
          ctx.font = fontSize + "em sans-serif";
          ctx.textBaseline = "top";
          const total = this.total();
          const textX = Math.round((width - ctx.measureText(total).width) / 2) + chart.legend.width + 1;
          const textY = height / 2;
          ctx.fillText(total, textX, textY);
          ctx.save();
        },
      }],
    });
  }

  canvasContext () {
    return this.chartTarget.getContext("2d");
  }

  labels () {
    this._labels = this._labels || Object.keys(this.dataset());
    return this._labels;
  }

  values () {
    this._values = this._values || Object.values(this.dataset());
    return this._values;
  }

  total () {
    return this.values().reduce((a, b) => a + b, 0);
  }

  dataset () {
    this._dataset = this._dataset || JSON.parse(this.data.get("dataset"));
    return this._dataset;
  }
}

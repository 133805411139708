// https://gist.github.com/oilvier/70abd45d1f2ffc98b568
// https://www.thewebflash.com/how-to-add-a-cross-browser-add-to-favorites-bookmark-button-to-your-website/

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect () {
    this.uri = this.data.get("uri") || window.location.href;
  }

  saveOrAlertBookmark (event) {
    const title = event.target.getAttribute("title") || document.title;

    if (window.external && ("AddFavorite" in window.external)) {
      // Internet Explorer
      window.external.AddFavorite(this.uri, title);
    } else if (window.sidebar && window.sidebar.addPanel) {
      // Firefox < 23
      window.sidebar.addPanel(title, this.uri, "");
    } else if (window.opera && window.print) {
      // Opera < 15
      return true;
    } else {
      // For the other browsers (mainly WebKit) we use a simple alert to inform users that they can add to bookmarks with ctrl+D/cmd+D
      alert(`This page can be bookmarked by pressing ${navigator.userAgent.toLowerCase().indexOf("mac") !== -1 ? "Command" : "CTRL"} + D on your computer keyboard or by using your mobile browser's bookmark menu.`);
    }

    return false;
  }
}

import { Controller } from "@hotwired/stimulus";

// Adapted official sample for stimulus
// https://developers.google.com/drive/picker/guides/sample
export default class extends Controller {
  static targets = ["fileId", "fileLink", "fileName"];
  static values = {
    appId: String,
    clientId: String,
    developerKey: String,
    folderId: String,
  };

  connect () {
    this.#initializeAuthClient();
    this.#loadPickerClient();
  }

  selectFile = (event) => {
    event.preventDefault();
    this.promptCallback = this.#buildSelectPicker;
    this.#promptSignIn();
  };

  uploadFile = (event) => {
    event.preventDefault();
    this.promptCallback = this.#buildUploadPicker;
    this.#promptSignIn();
  };

  #initializeAuthClient = () => {
    this.tokenClient = window.google.accounts.oauth2.initTokenClient({
      client_id: this.clientIdValue,
      scope: "https://www.googleapis.com/auth/drive.file",
      callback: async (response) => {
        if (response.error !== undefined) { throw (response); }

        this.accessToken = response.access_token;

        this.promptCallback();
      },
    });
  };

  #loadPickerClient = () => {
    if (!this.pickerClientReady) {
      window.gapi.load("client:picker", this.#initializePickerClient);
    }
  };

  #initializePickerClient = async () => {
    await window.gapi.client.load("https://www.googleapis.com/discovery/v1/apis/drive/v3/rest");
    this.pickerClientReady = true;
    if (this.promptCallback) { this.promptCallback(); }
  };

  #promptSignIn = () => {
    const prompt = this.accessToken ? "" : "consent";
    this.tokenClient.requestAccessToken({ prompt });
  };

  #buildSelectPicker = () => {
    if (this.accessToken && this.pickerClientReady) {
      const docsView = new window.google.picker.DocsView(window.google.picker.ViewId.PDFS)
        .setParent(this.folderIdValue);

      this.#buildPicker(docsView, "Select Signed LOI");
    }
  };

  #buildUploadPicker = () => {
    if (this.accessToken && this.pickerClientReady) {
      const docsUploadView = new window.google.picker.DocsUploadView(window.google.picker.ViewId.PDFS)
        .setParent(this.folderIdValue);

      this.#buildPicker(docsUploadView, "Upload Signed LOI");
    }
  };

  #buildPicker = (view, title) => {
    const picker = new window.google.picker.PickerBuilder()
      .setAppId(this.appIdValue)
      .setOAuthToken(this.accessToken)
      .setDeveloperKey(this.developerKeyValue)
      .addView(view)
      .enableFeature(window.google.picker.Feature.NAV_HIDDEN)
      .setTitle(title)
      .setCallback(this.#pickerCallback)
      .build();

    picker.setVisible(true);
  };

  #pickerCallback = (data) => {
    if (data.action === window.google.picker.Action.PICKED) {
      const file = data.docs[0];
      this.fileIdTarget.value = file.id;
      this.fileNameTarget.textContent = file.name;
      this.fileLinkTarget.href = file.url;
      this.fileLinkTarget.classList.remove("hidden");
      this.element.closest("form").dispatchEvent(new CustomEvent("google-picker:change"));
    }
  };
}

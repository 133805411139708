import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["nameAvailable",
    "nameUnavailable",
    "nameInput",
    "websiteAvailable",
    "websiteUnavailable",
    "websiteInput",
    "foundedAvailable",
    "foundedUnavailable",
    "foundedInput",
    "categorySelect",
    "industrySelect",
    "description",
    "descriptionCounter",
    "nextSubmit"];

  connect () {
    this.toggleIndustrySelect();
  }

  showNameInput () {
    this.nameInputTarget.classList.remove("hidden");
    this.checkValidForm();
  }

  hideNameInput () {
    this.nameInputTarget.value = this.nameInputTarget.defaultValue;
    this.nameInputTarget.classList.add("hidden");
    this.checkValidForm();
  }

  showWebsiteInput () {
    this.websiteInputTarget.classList.remove("hidden");
    this.checkValidForm();
  }

  hideWebsiteInput () {
    this.websiteInputTarget.value = this.websiteInputTarget.defaultValue;
    this.websiteInputTarget.classList.add("hidden");
    this.checkValidForm();
  }

  showFoundedInput () {
    this.foundedInputTarget.classList.remove("hidden");
    this.checkValidForm();
  }

  hideFoundedInput () {
    this.foundedInputTarget.value = this.foundedInputTarget.defaultValue;
    this.foundedInputTarget.classList.add("hidden");
    this.checkValidForm();
  }

  toggleIndustrySelect () {
    // sl-select values returns as an array
    const parentValue = Array.isArray(this.categorySelectTarget.value) ? this.categorySelectTarget.value[0] : this.categorySelectTarget.value;

    if (parentValue !== "") {
      this.industrySelectTarget.disabled = false;

      for (const industry of this.industrySelectTarget.children) {
        industry.hidden = industry.value !== "" && industry.dataset.parent !== parentValue;
        if (industry.hidden && industry.value === this.industrySelectTarget.value) {
          this.industrySelectTarget.value = null;
        }
      }
    } else {
      this.industrySelectTarget.disabled = true;
    }
    this.checkValidForm();
  }

  displayCharactersRemaining () {
    const currentLength = this.descriptionTarget.value.length;
    this.descriptionCounterTarget.textContent = `${currentLength}/45`;
  }

  checkValidForm () {
    if (this.hasNextSubmitTarget) {
      this.nextSubmitTarget.disabled = !(this.validName() && this.validWebsite() && this.validFounded() && this.validIndustry());
    }
  }

  validName () {
    return !this.hasNameAvailableTarget || ((this.nameAvailableTarget.checked && this.nameInputTarget.value !== "") || (this.nameUnavailableTarget.checked));
  }

  validWebsite () {
    return !this.hasWebsiteAvailableTarget || ((this.websiteAvailableTarget.checked && this.websiteInputTarget.value !== "") || (this.websiteUnavailableTarget.checked));
  }

  validFounded () {
    return !this.hasFoundedAvailableTarget || ((this.foundedAvailableTarget.checked && this.foundedInputTarget.value !== "") || (this.foundedUnavailableTarget.checked));
  }

  validIndustry () {
    return !this.hasIndustrySelectTarget || this.industrySelectTarget.value !== "";
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    incomeStatusUrl: String,
  };

  connect () {
    this.refreshInterval = 1000;

    if (this.refreshInterval) {
      this.startPolling();
    }
  }

  fetchState () {
    fetch(this.incomeStatusUrlValue)
      .then((response) => response.json())
      .then(data => {
        if (!data.incomeProcessing) {
          location.reload();
        }
      });
  }

  startPolling () {
    this.refreshTimer = setInterval(() => {
      this.fetchState();
    }, this.refreshInterval);
  }
}

import { Controller } from "@hotwired/stimulus";

// Originally pulled from OS. Will ultimately rely on shared UI component

export default class extends Controller {
  static targets = ["toggleable", "link"];

  connect () {
    this.handleForegroundClick = this.toggle.bind(this);
    this.linkTarget.addEventListener("click", this.handleForegroundClick);

    // Below is the logic to see if we click inside the dropdown, or outside
    const bgClick = (evt) => {
      let targetElement = evt.target; // clicked element

      do {
        if (targetElement === this.element) {
          return;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);

      // If we get here, it was a click outside and we need to toggle the dropdown off
      this.toggleOff();
    };
    this.handleBackgroundClick = bgClick.bind(this);
  }

  onDisconnect () {
    this.linkTarget.removeEventListener("click", this.handleForegroundClick);
  }

  toggle () {
    if (this.toggleableTarget.classList.contains("hidden")) {
      this.toggleOn();
    } else {
      this.toggleOff();
    }
  }

  toggleOn () {
    this.toggleableTarget.classList.remove("hidden");
    document.addEventListener("click", this.handleBackgroundClick);
  }

  toggleOff () {
    this.toggleableTarget.classList.add("hidden");
    document.removeEventListener("click", this.handleBackgroundClick);
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["approveButton"];

  changeText () {
    if (this.approveButtonTarget.id === "approved-unapprove-button") {
      this.approveButtonTarget.classList.remove("outlined");
      this.approveButtonTarget.classList.add("destructive");
      this.approveButtonTarget.innerHTML = "Unapprove";
    }
  }

  revertText () {
    if (this.approveButtonTarget.id === "approved-unapprove-button") {
      this.approveButtonTarget.classList.remove("destructive");
      this.approveButtonTarget.classList.add("outlined");
      this.approveButtonTarget.innerHTML = "Approved";
    }
  }
}

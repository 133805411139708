import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["errorContent"];

  open (event) {
    const data = event.detail[0];
    this.element.reset();
    window.open(data.url, "_blank");
  }

  showError () {
    this.errorContentTarget.classList.remove("hidden");
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["descriptionDropdown", "descriptionTextbox", "assigneeDropdown", "createButton", "inputToggle"];

  assignToDescription () {
    if (this.descriptionDropdownTarget.value !== "Create custom task") {
      this.descriptionTextboxTarget.value = this.descriptionDropdownTarget.value;
    }
  }

  showCustomInput () {
    if (this.descriptionDropdownTarget.value === "Create custom task") {
      this.descriptionDropdownTarget.classList.add("hidden");

      this.descriptionTextboxTarget.classList.remove("hidden");
      this.descriptionTextboxTarget.value = "";
      this.descriptionTextboxTarget.focus();

      this.inputToggleTarget.classList.remove("hidden");
    }
  }

  hideCustomInput () {
    this.descriptionDropdownTarget.classList.remove("hidden");
    this.descriptionDropdownTarget.firstChild.selected = true;
    this.descriptionTextboxTarget.value = "";

    this.descriptionTextboxTarget.classList.add("hidden");

    this.inputToggleTarget.classList.add("hidden");
    this.toggleCreateButton();
  }

  toggleCreateButton () {
    if (this.descriptionTextboxTarget.value && this.assigneeDropdownTarget.value) {
      this.createButtonTarget.removeAttribute("disabled");
    } else {
      this.createButtonTarget.setAttribute("disabled", true);
    }
  }
}

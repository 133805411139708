import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "errorNotification",
    "shareNotification",
    "pauseNotification",
    "selectSubmit",
    "selectSaved",
    "modalUpload",
    "modalForm",
    "modalHeader",
    "modalFileInput",
    "modalErrors",
    "modalProcessing",
    "modalUploadedContent",
    "modalFilename",
  ];

  toggleCta (_event) {
    this.selectSubmitTarget.classList.remove("hidden");
    this.selectSavedTarget.classList.add("hidden");
  }

  showErrorNotification (_event) {
    this.modalFormTarget.classList.add("hidden");
    this.pauseNotificationTarget.classList.add("hidden");
    this.errorNotificationTarget.classList.remove("hidden");
  }

  showUploadErrors (event) {
    const data = event.detail[0];
    data.errors.forEach(error => {
      const li = document.createElement("li");
      li.textContent = error;
      this.modalErrorsTarget.appendChild(li);
    });

    this.modalHeaderTarget.classList.replace("text-gray-900", "text-red-500");
    this.modalFileInputTarget.classList.replace("text-gray-500", "text-red-500");
    this.modalFileInputTarget.classList.add("border-red-500");
  }

  hideUploadErrors (_event) {
    while (this.modalErrorsTarget.firstChild) {
      this.modalErrorsTarget.removeChild(this.modalErrorsTarget.firstChild);
    }

    this.modalHeaderTarget.classList.replace("text-red-500", "text-gray-900");
    this.modalFileInputTarget.classList.replace("text-red-500", "text-gray-500");
    this.modalFileInputTarget.classList.remove("border-red-500");
  }

  showUploadedContent (event) {
    const data = event.detail[0];
    this.modalFilenameTarget.textContent = data.filename;
    this.modalFormTarget.classList.add("hidden");
    this.modalUploadedContentTarget.classList.remove("hidden");
  }

  showCimState (event) {
    const data = event.detail[0];

    if (data.type === "upload") {
      this.modalUploadTarget.classList.remove("hidden");
      this.shareNotificationTarget.classList.add("hidden");
      this.pauseNotificationTarget.classList.add("hidden");
      this.selectSubmitTarget.classList.add("hidden");
      this.selectSavedTarget.classList.remove("hidden");
    } else if (data.type === "share") {
      this.modalUploadTarget.classList.add("hidden");
      this.shareNotificationTarget.classList.remove("hidden");
      this.pauseNotificationTarget.classList.add("hidden");
      this.selectSubmitTarget.classList.add("hidden");
      this.selectSavedTarget.classList.remove("hidden");
    } else if (data.type === "pause") {
      this.pauseNotificationTarget.classList.remove("hidden");
      this.shareNotificationTarget.classList.add("hidden");
      this.modalUploadTarget.classList.add("hidden");
      this.selectSubmitTarget.classList.add("hidden");
      this.selectSavedTarget.classList.remove("hidden");
    } else if (data.type === "reselect") {
      this.shareNotificationTarget.classList.add("hidden");
      this.pauseNotificationTarget.classList.add("hidden");
      this.modalUploadTarget.classList.add("hidden");
      this.toggleCta();
    }
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["googleAddress", "streetAddress", "city", "state", "zipCode", "country", "lat", "lng", "googlePlaceId", "errorText", "nextSubmit"];

  connect () {
    if (typeof (window.google) !== "undefined") {
      this.autocomplete = new window.google.maps.places.Autocomplete(this.googleAddressTarget);
      this.autocomplete.setFields(["address_components", "geometry", "name", "formatted_address", "place_id"]);
      this.autocomplete.addListener("place_changed", this.validatePlace.bind(this));
    }
  }

  validatePlace () {
    if (this.autocomplete === undefined) {
      return;
    }

    const place = this.autocomplete.getPlace();

    if (this.placeChanged() && place) {
      this.validateAddress(place);

      if (place.address_components) {
        this.setAddressTargets(place.address_components);
      }

      if (place.geometry) {
        this.latTarget.value = place.geometry.location.lat();
        this.lngTarget.value = place.geometry.location.lng();
      }

      if (place.place_id) {
        this.googlePlaceIdTarget.value = place.place_id;
      }
      if (place.name && place.formatted_address) {
        // When searching by place name, the autofilled input box will not include the street number
        // place.name will either be street_number + route (i.e. 123 Main St) or a place name (i.e. Olive Garden)
        if (!place.formatted_address.includes(place.name)) {
          this.googleAddressTarget.value = `${place.name}, ${place.formatted_address}`;
        } else {
          this.googleAddressTarget.value = place.formatted_address;
        }
      }
    }
  }

  clearPlace () {
    this.clearAddressTargets();
    if (this.autocomplete !== undefined && this.autocomplete.getPlace()) {
      this.autocomplete.set("place", null);
    }
  }

  onBlur () {
    if (this.autocomplete === undefined) {
      return;
    }

    // Set small timeout because blur will occur before place_changed event causing the error to flash briefly
    setTimeout(() => this.validateAddress(this.autocomplete.getPlace()), 150);
  }

  placeChanged () {
    return this.googleAddressTarget.defaultValue !== this.googleAddressTarget.value;
  }

  clearAddressTargets () {
    this.streetAddressTarget.value = null;
    this.cityTarget.value = null;
    this.stateTarget.value = null;
    this.zipCodeTarget.value = null;
    this.countryTarget.value = null;

    this.latTarget.value = null;
    this.lngTarget.value = null;
    this.googlePlaceIdTarget.value = null;
  }

  validateAddress (place) {
    if (this.placeChanged()) {
      if (this.googleAddressTarget.value === "" || (place && place.address_components)) {
        this.removeError();
      } else {
        this.showError();
      }
    } else {
      this.removeError();
    }
    this.element.closest("form").dispatchEvent(new CustomEvent("google-address:change"));
  }

  showError () {
    this.googleAddressTarget.classList.add("error");
    this.errorTextTarget.classList.remove("hidden");
    if (this.hasNextSubmitTarget) this.nextSubmitTarget.disabled = true;
  }

  removeError () {
    this.googleAddressTarget.classList.remove("error");
    this.errorTextTarget.classList.add("hidden");
    if (this.hasNextSubmitTarget) this.nextSubmitTarget.disabled = false;
  }

  setAddressTargets (addressComponents) {
    let streetNumber = "";
    let route = "";

    addressComponents.forEach(component => {
      const name = component.long_name;
      const type = component.types[0];

      switch (type) {
        case "street_number":
          streetNumber = name;
          break;
        case "route":
          route = name;
          break;
        case "locality":
        case "sublocality_level_1": // specific to NYC
          this.cityTarget.value = name;
          break;
        case "administrative_area_level_1":
          this.stateTarget.value = name;
          break;
        case "postal_code":
          this.zipCodeTarget.value = name;
          break;
        case "country":
          this.countryTarget.value = name;
          break;
      }
    });

    this.streetAddressTarget.value = `${streetNumber} ${route}`;
  }

  keydown (event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }
}

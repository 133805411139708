import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["drawer", "drawerFlash"];

  openDrawer () {
    this.drawerTarget.show();
    this.drawerFlashTarget.setAttribute("id", "drawer-flash");
  }

  closeDrawer () {
    this.drawerTarget.hide();
    this.drawerFlashTarget.innerHTML = "";
    this.drawerFlashTarget.removeAttribute("id", "drawer-flash");
  }
}

document.addEventListener("DOMContentLoaded", function (_event) {
  const ndaUpload = document.querySelector(".broker_nda_upload");
  ndaUpload && ndaUpload.addEventListener("click", event => {
    documentUploadUpdate("nda", event.target);
  });

  const cimUpload = document.querySelector(".broker_cim_upload");
  cimUpload && cimUpload.addEventListener("click", event => {
    documentUploadUpdate("cim", event.target);
  });

  function documentUploadUpdate (documentType, element) {
    const uploadNowRadio = document.getElementById(`${documentType}_selection_upload`);
    const uploadModal = document.querySelector(".micromodal-slide");
    const uploadLaterNotification = document.getElementById("manual-upload-notification");
    if (uploadNowRadio.checked) {
      uploadModal.classList.add("is-open");
    } else {
      uploadLaterNotification.classList.remove("hidden");
      uploadLaterNotification.classList.add("visible");
      element.innerText = "Saved";
    }
  }
});
